(function (angular, _) {
    var MyHippoCommonDirectives = angular.module('MyHippoCommons.Directives');

    MyHippoCommonDirectives.directive('mhStateDropdown', mhStateDropdown);
    mhStateDropdown.$inject = ['$timeout', 'toaster'];
    function mhStateDropdown ($timeout, toaster) {
        return {
            restrict: 'E',
            transclude: true,
            scope: {
                ngModel: '=',
                forms: '='
            },
            template: `
                <md-input-container class="md-input-has-placeholder">
                    <md-select aria-label="select state" ng-model="ngModel" name="state" required>
                        <md-option ng-repeat="(abbr, state) in states" value="{{abbr}}">
                            {{abbr.toUpperCase()}}
                        </md-option>
                    </md-select>
                    <div ng-messages="forms.escrow.state.$error">
                        <div ng-message="required">Required</div>
                    </div>
                </md-input-container>
                <label>State</label>
            `,
            link: function ($scope, $element, $attrs, ngModelCtrl) {
                $scope.states = {
                    'al': 'Alabama',
                    'ak': 'Alaska',
                    'az': 'Arizona',
                    'ar': 'Arkansas',
                    'ca': 'California',
                    'co': 'Colorado',
                    'ct': 'Connecticut',
                    'de': 'Delaware',
                    'dc': 'District Of Columbia',
                    'fl': 'Florida',
                    'ga': 'Georgia',
                    'hi': 'Hawaii',
                    'id': 'Idaho',
                    'il': 'Illinois',
                    'in': 'Indiana',
                    'ia': 'Iowa',
                    'ks': 'Kansas',
                    'ky': 'Kentucky',
                    'la': 'Louisiana',
                    'me': 'Maine',
                    'md': 'Maryland',
                    'ma': 'Massachusetts',
                    'mi': 'Michigan',
                    'mn': 'Minnesota',
                    'ms': 'Mississippi',
                    'mo': 'Missouri',
                    'mt': 'Montana',
                    'ne': 'Nebraska',
                    'nv': 'Nevada',
                    'nh': 'New Hampshire',
                    'nj': 'New Jersey',
                    'nm': 'New Mexico',
                    'ny': 'New York',
                    'nc': 'North Carolina',
                    'nd': 'North Dakota',
                    'oh': 'Ohio',
                    'ok': 'Oklahoma',
                    'or': 'Oregon',
                    'pa': 'Pennsylvania',
                    'ri': 'Rhode Island',
                    'sc': 'South Carolina',
                    'sd': 'South Dakota',
                    'tn': 'Tennessee',
                    'tx': 'Texas',
                    'ut': 'Utah',
                    'vt': 'Vermont',
                    'va': 'Virginia',
                    'wa': 'Washington',
                    'wv': 'West Virginia',
                    'wi': 'Wisconsin',
                    'wy': 'Wyoming'
                };
            }
        };
    }
})(window.angular, window._);
